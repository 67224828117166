<template>
    <div>
        <div class="position-fixed">
            <van-search v-model="keywords" show-action placeholder="请输入搜索关键词" @search="onSearch" @cancel="onCancel"/>
            <van-tabs v-model="status" color="#333" @click="onClick">
                <van-tab title="未发送"></van-tab>
                <van-tab title="待回复"></van-tab>
                <van-tab title="已完成"></van-tab>
            </van-tabs>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="margin-top-100 full-height">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getProjects"
            >
                <van-cell center v-for="(x,index) in list" :key="index" class="cell-inset" :url="x.url">
                    <template #title>
                        <van-row>
                            <van-col span="22">
                                <div class="custom-title"><div class="left-title">项目编号:</div><span>{{x.projectitemcode}}</span></div>
                            </van-col>
                            <!-- <van-col span="10"> -->
                                <!-- <van-tag type="danger" v-if="x.is_closed == 1">已关闭</van-tag>
                                <van-tag type="success" v-if="x.is_reply == 1">已回复</van-tag> -->
                            <!-- </van-col> -->
                            <van-col span="22">
                                <div class="custom-title one-line"><div class="left-title">项目名称:</div><span class="weight-blod">{{(x.short_name ? x.short_name : x.projectitemname) | ellipsis}}</span></div>
                            </van-col>

                            <van-col span="22">
                                <div class="custom-title one-line"><div class="left-title">生产主责:</div><span>{{(x.projectitemcomname+'-'+x.projectitemdeptname) | ellipsis}}</span></div>
                            </van-col>

                            <van-col span="22">
                                <div class="custom-title"><div class="left-title">项目经理:</div><span class="weight-blod">{{x.projectmanagername}}</span></div>
                            </van-col>

                            <!-- <van-col span="22">
                                <div class="custom-title"><div class="left-title">主创/设总:</div><span class="weight-blod">{{x.projectpaname}}</span></div>
                            </van-col> -->

                            <div  v-if="x.send_time && x.end_time && x.is_reply === 0">
                                <van-col span="12">
                                    <div class="custom-title"><div class="left-title">发送时间:</div><span>{{x.send_time}}</span></div>
                                </van-col>
                                <van-col span="10">
                                    <div class="custom-title">截止时间:<span>{{x.end_time}}</span></div>
                                </van-col>
                            </div>

                            <div  v-if="x.is_reply == 1 || x.is_closed == 1">
                                <van-col span="12">
                                    <div class="custom-title"><div class="left-title">问卷得分:</div><span>{{x.score}}</span></div>
                                </van-col>
                                <van-col span="10">
                                    <div class="custom-title">回复时间:<span>{{x.reply_time}}</span></div>
                                </van-col>
                            </div>


                            <div  v-if="x.name && x.mobile">
                                <van-col span="12">
                                    <div class="custom-title"><div class="left-title">联系人:</div><span>{{x.name}}</span></div>
                                </van-col>
                                <van-col span="10">
                                    <div class="custom-title">电话:<span>{{x.mobile}}</span></div>
                                </van-col>
                            </div>
                        </van-row>

                        <!-- <div class="custom-title"><div class="left-title">项目编号:</div><span>{{x.projectitemcode}}</span></div>
                        <div class="custom-title"><div class="left-title">项目名称:</div><span>{{x.short_name ? x.short_name : x.projectitemname}}</span></div>
                        <div class="custom-title">
                            <div class="div-half m-r-10"><div class="left-title">主创/设总:</div><span>{{x.projectpaname}}</span></div>
                            <div class="div-half w-p-60 one-line">生产主责:<span>{{x.projectitemcomname}}-{{x.projectitemdeptname}}</span></div>
                        </div>
                        <div class="custom-title" v-if="x.send_time && x.end_time && x.is_reply === 0">
                            <div class="div-half m-r-10 w-p-45">问卷发送时间:<span>{{x.send_time}}</span></div>
                            <div class="div-half w-p-50 one-line">问卷截止时间:<span>{{x.end_time}}</span></div>
                        </div>

                        <div class="custom-title" v-if="x.is_reply == 1">
                            <div class="div-half m-r-10"><div class="left-title">问卷得分:</div><span>{{x.score}}</span></div>
                            <div class="div-half one-line">回复时间:<span>{{x.reply_time}}</span></div>
                        </div>

                        <div class="custom-title" v-if="x.name && x.mobile">
                            <div class="div-half m-r-10"><div class="left-title">联系人:</div><span>{{x.name}}</span></div>
                            <div class="div-half w-p-60 one-line">电话:<span>{{x.mobile}}</span></div>
                        </div> -->
                        
                        <van-tag type="danger" class="right-top-tag" v-if="x.is_closed == 1">已关闭</van-tag>
                        <van-tag type="primary" class="right-top-tag" v-if="x.is_reply == 1">已回复</van-tag>
                        <van-tag type="warning" class="right-top-tag" v-if="x.apply == 1">特殊情况申请中</van-tag>

                    </template>
                </van-cell>
            </van-list>
        </van-pull-refresh>

    </div>
</template>
<script>
import {projects} from "@/api/user";
import {ticket, shareTitle, shareUrl, shareImg, shareDesc, commonShare} from "@/api/wxshare";
export default {
    name:'Home',
    data() {
        return {
            keywords:'',
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            page:1,
            pageSize:20,
            status:0,
        }
    },
    filters:{
        ellipsis(value){
          if (!value) return '';
          if (value.length > 22) {
            return value.slice(0,22) + '...'
          }
          return value
        }
    },
    created() {
        let _this = this;
        _this.updateShare();
    },
    methods: {
        async updateShare() {
            let _this = this;
            const res = await ticket({url:window.location.href});
            if (res.code == 1) {
                commonShare(res.data, shareTitle, window.location.href, shareImg, shareDesc)
            }
        },
        onRefresh() {
            // 清空列表数据
            let _this = this;
            _this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            _this.loading = true;
            _this.page = 1;
            _this.getProjects();
        },
        onClick(name, title) {
            let _this = this;
            _this.refreshing = true;
            _this.onRefresh();
        },
        onSearch(val) {
            let _this = this;
            _this.refreshing = true;
            _this.onRefresh();
        },
        onCancel() {
            let _this = this;
            _this.refreshing = true;
            _this.onRefresh();
        },
        async getProjects() {
            let _this = this;
            if (_this.refreshing) {
                _this.list = [];
                _this.refreshing = false;
            }
            const res = await projects({page:_this.page,pageSize:_this.pageSize,keywords:_this.keywords,status:_this.status});
            setTimeout(() => {
                if (res.code == 1) {
                    if (res.data.list.length > 0) {
                        res.data.list.forEach(function(v, k) {
                            _this.list.push(v);
                        })
                        _this.page++;
                        _this.loading = false;
                    } else {
                        _this.finished = true;
                        _this.loading = false;
                    }
                } else {
                    _this.finished = true;
                    _this.loading = false;
                }
            }, 100)
        }
    },
}    
</script>
